<template>
    <div class="commodity-detail">
      <app-detail :opt="opt" ref="detail"></app-detail>
    </div>
  </template>
  <script>
    let priceVali = (rule, value, callback) => {
      var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
      if (!reg.test(value)) {
        callback(
          new Error("请输入正数，且小数点后最多保留2位")
        );
      } else {
        callback();
      }
    };
    let validItemsName = (rule, value, callback) => {
      if(!value) {
        callback(new Error("请输入商品名称"));
      } else {
        //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
        let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
        if (!reg.test(value)) {
            callback(new Error("商品名称格式不对或超过32个字符或超过32个字符"));
        } else {
            callback();
        }
      }
    };
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
      data() {
        let _this = this;
        return {
          opt: {
            title: "商品详情",
            form: [
              {
                label: "商品名称",
                key: "name",
                maxlength: 64,
                rules: [{
                  required: true,
                  trigger: "change",
                  validator: validItemsName,
                }]
              },
              {
                label: "商品编号",
                key: "code",
              },
              {
                label: "商品品类",
                key: "type",
                type: "select",
                opt: {
                  list: []
                },
                rules: [{
                  required: true,
                  trigger: "change"
                }]
              },
              {
                label: "发布商家",
                key: "merchantName",
                // type: "select",
                // opt: {
                //   list: []
                // },
                // rules: [{
                //   required: true,
                //   trigger: "change"
                // }]
              },
              {
                label: "供应商名称",
                key: "costId",
                type: "select-filterable",
                opt: {
                  list: [],
                  change(e) {
                  _this.supplierChange(e)
                  }
                },
                rules: [{
                  required: true,
                  trigger: "change"
                }]
              },
              {
                label: "成本价（元）",
                key: "costPrice",
                rules: [{
                  required: true,
                  trigger: "change",
                  validator: priceVali,
                }]
              },
              {
                label: "销售价（元）",
                key: "sellPrice",
                rules: [{
                  required: true,
                  trigger: "change",
                  validator: priceVali,
                }]
              },
              {
                label: "经销价（元）",
                key: "supplyPrice",
                rules: [{
                  required: true,
                  trigger: "change",
                  validator: priceVali,
                }]
              },
              {
                label: "售价范围",
                key: "sellRange",
              },
              {
                label: "商品详细描述",
                key: "detail",
                type: "textarea",
                maxlength: 500,
                placeholder: " "
              },
              {
                label: "商品图片",
                key: "imgs",
                type: "upload-img-form",
                rules: [{
                  required: true
                }],
                opt: {
                  url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                  urlKey: "picPath",
                  limitFileNameLength: 90,
                  upload(data) {
                    console.log(data);
                    return true;
                  },
                  remove(file, fileList) {
                    console.log(file, fileList);
                    return true;
                  }
                }
              },
              {
                label: "商品状态",
                key: "auditState",
              },
              {
                label: "商品审核",
                key: "auditState2",
                type: "switch",
                opt: {
                  activeText: "通过",
                  inactiveText: "不通过",
                  change(e) {
                    _this.stateChange(e)
                  }
                }
              },
              {
                label: " ",
                key: "failReason",
                placeholder: "请输入商品审核不通过的原因",
              },
            ],
            buttons: [{
              name: "提交",
              show: ["edit"],
              cb(form) {
                if(form.lowPrice && form.hightPrice) {
                  if(form.sellPrice < form.lowPrice || form.sellPrice > form.hightPrice){
                    return _this.$message({
                        message: '销售价不能超出售价范围',
                        type: 'warning'
                    })
                  }
                }else{
                  if(form.sellPrice < form.supplyPrice) {
                    return _this.$message({
                        message: '销售价不能小于经销价',
                        type: 'warning'
                    })
                  }
                }
                let dto = {
                  type: form.type,
                  name: form.name,
                  code: form.code,
                  sellPrice: form.sellPrice,
                  supplyPrice: form.supplyPrice,
                  costPrice: form.costId == '1' ? form.sellPrice : form.costPrice,
                  detail: form.detail,
                  costId: form.costId == '1' ? null : form.costId,
                  auditState: form.auditState2 ? 1 : 2,
                  failReason: form.failReason ? form.failReason : "",
                  lowPrice: form.lowPrice,
                  hightPrice: form.hightPrice,
                  id: form.id
                };
                _this.supplierList.forEach(item => {
                  if (form.costId == item.value) {
                    dto.costName = item.label
                  }
                })
                if (dto.costName == '自营' || !dto.costName) {
                  dto.isSelfSale = 1
                } else {
                  dto.isSelfSale = 0
                }
                dto.picList = [];
                form.imgs.forEach(item => {
                  dto.picList.push(item.url);
                })
                let url = "mall-service/items/update";
                let message = "审核成功";
                _this.post(url, dto).then(function () {
                  _this.$message({
                    showClose: true,
                    message: message,
                    type: "success"
                  });
                  _this.$back();
                });
              }
            }],
            get(opt) {
              _this.get("mall-service/items/detail/" + opt.id).then(data => {
                if(data.isSelfSale == 1) {
                  data.costId = "1"
                }
                data.imgs = [];
                data.picList.forEach((item, index) => {
                  data.imgs.push({
                    name: `${index}.jpg`,
                    url: item
                  });
                })
                data.auditState = ["待审核","审核通过","审核不通过"][data.auditState];
                data.auditState2 = data.auditState == "审核不通过" ? false : true
                data.costPrice = data.costPrice == null ? '' : data.costPrice.toFixed(2).toString();
                data.sellPrice = data.sellPrice == null ? '' : data.sellPrice.toFixed(2).toString();
                data.supplyPrice = data.supplyPrice == null ? '' : data.supplyPrice.toFixed(2).toString();
                if(data.lowPrice && data.hightPrice){
                    data.sellRange = data.lowPrice + ' ~ ' + data.hightPrice
                }else{
                    data.sellRange = '--'
                }
                _this.auditStateValue = data.auditState
                opt.cb(data);
              });
            },
  
          },
          auditStateValue: '',
          supplierList: []
        };
      },
      activated() {
        console.log("commodity-detail activated!!");
        this.$refs.detail.title = ["商品详情","审核商品","编辑商品"][this.$route.params.btn];
        this.opt.form.forEach(item => {
              // 判断审核状态是否显示
              if (item.key == 'auditState') {
                if (this.$route.params.type == "show") {
                  item.notShow = false
                } else {
                  item.notShow = true
                }
              }
              if (item.key == 'auditState2' || item.key == 'failReason') {
                  if (this.$route.params.type == "edit") {
                    item.notShow = false
                  } else {
                    item.notShow = true
                  }
                }
              if (item.key == "imgs") {
                if (this.$route.params.btn == '1') {
                  item.rules[0].required = false
                } else {
                  item.rules[0].required = true
                }
              }
              // 判断点击编辑按钮与审核按钮时可进行的操作
              if (this.$route.params.btn == '2') {
                if (this.$route.query.auditState == "审核通过") {
                  if (item.key == 'sellPrice' || item.key == 'name') {
                    item.disabled = false;
                  } else {
                    item.disabled = true;
                  }
                  document.getElementsByClassName('commodity-detail')[0].classList.add('img-edit')
                } else {
                  if (item.key == 'code' || item.key == 'sellRange' || item.key == 'supplyPrice' || item.key == 'costPrice'  || item.key == 'type' || item.key == 'merchantName' || item.key == 'costId') {
                    item.disabled = true;
                  } else {
                    item.disabled = false;
                  }
                  document.getElementsByClassName('commodity-detail')[0].classList.remove('img-edit')
                }
              } else if (this.$route.params.btn == '1') {
                if (item.key == 'auditState2' || item.key == 'failReason') {
                  item.disabled = false
                } else {
                  item.disabled = true;
                }
                document.getElementsByClassName('commodity-detail')[0].classList.add('img-edit')
  
              } else {
                item.disabled = true;
              }
        })
        this.supplierList = [];
        let merchantId = this.$route.query.merchantId;
        this.getCategory(merchantId);
        this.getMerchantList();
      },
      methods: {
        stateChange(e) {
          this.opt.form.forEach(item => {
            if (item.key == 'failReason') {
              if(e == false && this.$route.params.type == "edit" || this.$route.params.type == "show" && this.auditStateValue == '审核不通过') {
                item.notShow = false
              } else {
                item.notShow = true
              }
            }
          })
        },
        supplierChange(e) {
          this.opt.form.forEach(item => {
            if (item.key == 'costPrice') {
              if (e == '1') {
                item.notShow = true
                item.rules[0].required = false
                item.isRequired = false
                item.rules[0].validator = ''
              } else {
                item.rules[0].required = true
                item.notShow = false
                item.isRequired = true
                item.rules[0].validator = priceVali
              }
            }
          })
        },
        // 获取商品品类
        getCategory(merchantId) {
          let dto = {
            pageNum: 1,
            pageSize: 9999,
            isDisable: 1,
            state: 1,//默认传1
            merchantId: merchantId,
          };
          this.post("mall-service/category/page/query", dto, {
            isUseResponse: true
          }).then(res => {
            var array = res.data.list;
            array.forEach(item => {
              item.value = item.id;
              item.label = item.name;
            })
            this.opt.form.forEach(item => {
              if (item.key == "type") {
                item.opt.list = array;
              }
            })
  
          })
        },
        // 获取供应商列表
        getMerchantList() {
          cabinetUtil.getMerchantList({roleType: 2}).then(res => {
            let getMerchantList = res && res.length > 0 ? res : [];
            getMerchantList.unshift({
              label: '自营',
              value: '1',
            })
            this.opt.form.forEach(item => {
              if(item.key == 'costId') {
                item.opt.list = res
                this.supplierList = item.opt.list
              }
            })
          })
        },
      }
    };
  </script>
  <style lang="scss">
    .commodity-detail {
      .el-form-item__label {
        flex: none !important;
        width: 220px;
      }
    }
    .img-edit {
      .el-upload {
        display: none !important; 
      }
      .el-icon-delete {
        display: none !important; 
      }
    }
      .action-but-item {
        padding-bottom: 10px;
      }
  </style>